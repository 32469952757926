.share_content{
  width: 100%;
  height: 100%;
  .content{
    // height: calc(100vh - 100px); 
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    .video_box{
      flex: 1;
      width: 100%;
      overflow: hidden;
      position: relative;
      .video{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
      }
      .info{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: .15rem;
        left: .16rem;
        max-width: 2.1rem;
        height: .34rem;
        background-color: rgba(0, 0, 0, 0.2);
        overflow: hidden;
        border-radius: .34rem;
        padding-left: .02rem;
        padding-right: .05rem;
        .avatar{
          width: .3rem;
          height: .3rem;
          border-radius: 50%;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            width: 100%;
          }
        }
        .name{
          flex: 1;
          overflow: hidden;  
          white-space: nowrap;  
          text-overflow: ellipsis; 
          color: #fff;
          font-size: .12rem;
          line-height: 1.4;
          padding-left: .03rem;
          padding-right: .05rem;
        }
        .follow{
          width: .67rem;
          font-size: .12rem;
          height: .24rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: .24rem;
          border: none;
          outline: none;
          background-color: #7A4BFA;
          color: #fff;
        }
      }
      .mesg_box{
        position: absolute;
        left: .12rem;
        bottom: .7rem;
        font-size: .12rem;
        line-height: 1.333;
        color: rgba(255, 255, 255, 0.8);
        width: 3rem;
        height: 1.2rem;
        overflow: hidden;
        .item{
          
          p{
            display: inline-block;
            background-color: rgb(52 52 52 / 35%);
            padding: .04rem .07rem;
            border-radius: .2rem;
            i{
              font-style: inherit;
              margin-right: .07rem;
              color: rgba(255, 255, 255, 0.5);
            }
          }
          &.mb-4{
            margin-bottom: .04rem;
          }
        }
      }
      .des{
        width: 2.72rem;
        height: .4rem;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
        overflow:hidden;
        font-size: .14rem;
        line-height: 1.4;
        position: absolute;
        left: .15rem;
        bottom: .16rem;
        color: #fff;
      }
      .feed_box{
        position: absolute;
        right: .14rem;
        bottom: .16rem;
        width: .36rem;
        z-index: 6;
        // height: 1.26rem;
        .item{
          display: flex;
          flex-direction: column;
          margin-bottom: .154rem;
          &:last-child{
            margin-bottom: 0;
          }
          button{
            border: none;
            outline: none;
            background-color: transparent;
            width: 100%;
            line-height: 0;
            img{
              width: 100%;
            }
          }
          p{
            font-size: .14rem;
            line-height: 1.4;
            color: #fff;
            text-align: center;
          }
        }
      }
    }
    .send_msg{
      height: .64rem;
      background: rgba(0, 0, 0, 0.8);
      width: 100%;
      padding: .12rem;
      display: flex;
      align-items: center;
      justify-content: center;
      p{
        // width: 3.5rem;
        flex: 1;
        // padding: .1rem .15rem ;
        line-height: 1.4;
        font-size: .14rem;
        border: none;
        // background: #1A1A1A;
        outline: none;
        color: #fff;
        // height: .4rem;
      }
      button{
        width: .67rem;
        font-size: .12rem;
        height: .24rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: .24rem;
        border: none;
        outline: none;
        background-color: #7A4BFA;
        color: #fff;
      }
    }
  }
  .loading{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 999999;
    pointer-events: none;
    background-color: #000;
    transition: all linear 0.3s;
    .img_box{
      width: 2.6rem;
      -webkit-animation: shake 3s linear infinite;
      animation: shake 3s linear  infinite;
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
      img{
        width: 100%;
      }
    }
    h6{
      color: #fff;
      font-size: .2rem;
    }
  }
}



@-webkit-keyframes shake{
//   0% { -webkit-transform: rotate(0deg) translate(0px); }
//   25% { -webkit-transform: rotate(-10deg) translate(-10px); }
//   50% { -webkit-transform: rotate(0deg) translate(0px); }
//   75% { -webkit-transform: rotate(10deg) translate(10px); }
//   100% { -webkit-transform: rotate(0deg) translate(0px); }
  0% { -webkit-transform:  translateY(0px); }
  25% { -webkit-transform: translateY(-0.075rem); }
  50% { -webkit-transform:  translate(0px); }
  75% { -webkit-transform:  translateY(.1rem); }
  100% { -webkit-transform:  translateY(0px); }
}
@keyframes shake{
  // 0% { -webkit-transform: rotate(0deg) translate(0px); }
  // 25% { -webkit-transform: rotate(-10deg) translate(-10px); }
  // 50% { -webkit-transform: rotate(0deg) translate(0px); }
  // 75% { -webkit-transform: rotate(10deg) translate(10px); }
  // 100% { -webkit-transform: rotate(0deg) translate(0px); }
  0% { -webkit-transform:  translateY(0px); }
  25% { -webkit-transform: translateY(-0.075rem); }
  50% { -webkit-transform:  translate(0px); }
  75% { -webkit-transform:  translateY(.1rem); }
  100% { -webkit-transform:  translateY(0px); }
} 
