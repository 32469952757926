.container{
  // max-width: 425px;
  width: 100%;
  margin: auto;
  background: #000;
  max-height: 100vh;
  overflow: hidden;
  // height: calc(100vh - 100px); 
}

@media (max-width: 750px) {
  .container{
    max-width: 100%;
  }
 }