.personal_content{
  width: 100%;
  height: 100%;
  .content{
    // height: calc(100vh - 100px); 
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: rgb(0, 0, 0);
    .top_block{
      width: 100%;
      height: 3rem;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      .info{
        width: 100%;
        padding: 0 .16rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        bottom: .25rem;
        .avatar_box{
          width: .72rem;
          height: .72rem;
          overflow: hidden;
          border-radius: .5rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .text{
          flex: 1;
          padding-left: .07rem;
          .name{
            line-height: .25rem;
            font-size: .2rem;
            color: #fff;
            font-weight: 300;
          }
          .id{
            font-size: .14rem;
            line-height: .2rem;
            color: rgba(255, 255, 255, 0.65);
          }
        }
      }
    }
    .bottom_block{
      position: relative;
      background-color: rgb(0, 0, 0);
      height: 5rem;
      border-top-left-radius: .16rem;
      border-top-right-radius: .16rem;
      margin-top: -0.13rem;
      padding-top: .15rem;
      .des{
        font-size: .14rem;
        line-height: .2rem;
        color: #fff;
        padding: 0 .15rem;
        width: 2.1rem;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:3;
        overflow:hidden;
        span{
          color: #737373;
        }
      }
      .tag{
        margin-top: .1rem;
        padding: 0 .15rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        .item{
          background-color: #323232;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: .02rem 0;
          padding-left: .02rem;
          padding-right: .05rem;
          font-size: .12rem;
          line-height: 1.4;
          min-width: .34rem;
          color: #fff;
          margin-right: .08rem;
          img{
            width: .18rem;
            height: .18rem;
          }
        }
      }
      .follow{
        padding: 0 .08rem;
        margin-top: .16rem;
        button{
          width: 100%;
          height: .4rem;
          background-color: #7A4BFA;
          border-radius: .4rem;
          border: none;
          outline: none;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          line-height: 1.4;
          font-size: .14rem;
          span{
            margin-right: .06rem;
            font-size: .16rem;
            line-height: 1;
            margin-top: -0.03rem;
          }
        }
      }
      .video_list{
        margin-top: .24rem;
        .tabTitle{
          position: sticky;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 9999;
          background-color: rgb(0, 0, 0);
          .item{
            flex: 1;
            text-align: center;
            color: #808080;
            border-bottom: .01rem solid #333333;
            font-size: .16rem;
            line-height: .2rem;
            position: relative;
            height: .42rem;
            display: flex;
            align-items: center;
            justify-content: center;
            &.active{
              color: #fff;
              border-bottom: .01rem solid #7A4BFA;
            }
          }
        }

        .tabContent{
          width: 200vw;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          overflow: hidden;
          transition: all linear .3s;
          padding-bottom: .68rem;
          box-sizing: content-box;
          .postsBox,.likesBox{
            width: 100vw;
            padding: .12rem;
            padding-top:  .08rem;


              column-count: 2;
              column-gap: 0;
         
             .item {
              padding: .03rem;
              position: relative;
              counter-increment: count;
              img, video{
                display: block;
                width: 100%;
                height: auto;
              }
              // &::after {
              //   position: absolute;
              //   display: block;
              //   top: 2px;
              //   left: 2px;
              //   width: 24px;
              //   height: 24px;
              //   text-align: center;
              //   line-height: 24px;
              //   background-color: #000;
              //   color: #fff;
              //   content: counter(count);
              // }
          }
          
        
      
         
          }
          .postsBox{
            // background-color: #7A4BFA;
            // height: 200vh;
          }
          .likesBox{
            // background-color: aquamarine;
            // height: 300vh;
          }
        }
      }
    }
    .send_msg{
      height: .64rem;
      background: rgba(0, 0, 0, 0.8);
      width: 100%;
      padding: .12rem;
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      left: 0;
      bottom: .0rem;
      z-index: 9999;
      p{
        // width: 3.5rem;
        flex: 1;
        // padding: .1rem .15rem ;
        line-height: 1.4;
        font-size: .14rem;
        border: none;
        // background: #1A1A1A;
        outline: none;
        color: #fff;
        // height: .4rem;
      }
      button{
        width: .67rem;
        font-size: .12rem;
        height: .24rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: .24rem;
        border: none;
        outline: none;
        background-color: #7A4BFA;
        color: #fff;
      }
    }
  }
  .loading{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 999999;
    pointer-events: none;
    background-color: #000;
    transition: all linear 0.3s;
    .img_box{
      width: 2.6rem;
      -webkit-animation: shake 5s linear infinite;
      animation: shake 5s linear infinite;
      -webkit-transform-origin: 50% 100%;
      transform-origin: 50% 100%;
      img{
        width: 100%;
      }
    }
    h6{
      color: #fff;
      font-size: .2rem;
    }
  }
  .nodata{
    width: calc(100vw - .24rem);
    color: #fff;
    text-align: center;
    font-size: .14rem;
  }
}


@-webkit-keyframes shake{
  //   0% { -webkit-transform: rotate(0deg) translate(0px); }
  //   25% { -webkit-transform: rotate(-10deg) translate(-10px); }
  //   50% { -webkit-transform: rotate(0deg) translate(0px); }
  //   75% { -webkit-transform: rotate(10deg) translate(10px); }
  //   100% { -webkit-transform: rotate(0deg) translate(0px); }
    0% { -webkit-transform:  translateY(0px); }
    25% { -webkit-transform: translateY(-0.075rem); }
    50% { -webkit-transform:  translate(0px); }
    75% { -webkit-transform:  translateY(.1rem); }
    100% { -webkit-transform:  translateY(0px); }
  }
  @keyframes shake{
    // 0% { -webkit-transform: rotate(0deg) translate(0px); }
    // 25% { -webkit-transform: rotate(-10deg) translate(-10px); }
    // 50% { -webkit-transform: rotate(0deg) translate(0px); }
    // 75% { -webkit-transform: rotate(10deg) translate(10px); }
    // 100% { -webkit-transform: rotate(0deg) translate(0px); }
    0% { -webkit-transform:  translateY(0px); }
    25% { -webkit-transform: translateY(-0.075rem); }
    50% { -webkit-transform:  translate(0px); }
    75% { -webkit-transform:  translateY(.1rem); }
    100% { -webkit-transform:  translateY(0px); }
  } 
  

  @media screen and (min-width: 1200px) { 
    .personal_content .content .bottom_block .video_list .tabContent .postsBox, .personal_content .content .bottom_block .video_list .tabContent .likesBox{
      column-count: 5;
    }
  }

  @media (min-width: 780px) and (max-width: 1200px) { 
    .personal_content .content .bottom_block .video_list .tabContent .postsBox, .personal_content .content .bottom_block .video_list .tabContent .likesBox{
      column-count: 4;
    }
  }