iframe {
  display: none;
}
body,html{
  background: rgb(0, 0, 0);
}

/* 兼容 iOS < 11.2 */
body {
  padding-top: constant(safe-area-inset-top);   //为导航栏+状态栏的高度 88px            
  padding-left: constant(safe-area-inset-left);   //如果未竖屏时为0                
  padding-right: constant(safe-area-inset-right); //如果未竖屏时为0                
  padding-bottom: constant(safe-area-inset-bottom);//为底下圆弧的高度 34px       
}
/* 兼容 iOS >= 11.2 */
body {
  padding-top: env(safe-area-inset-top);   //为导航栏+状态栏的高度 88px            
  padding-left: env(safe-area-inset-left);   //如果未竖屏时为0                
  padding-right: env(safe-area-inset-right); //如果未竖屏时为0                
  padding-bottom: env(safe-area-inset-bottom);//为底下圆弧的高度 34px       
}