.WalletCon{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .openmodelBtn{
    display: flex;
    align-items: center;
    border: 1px solid #dee2e6;
    color: #fff;
    background-color: #6c757d;
    font-size: .26rem;
    line-height: 1.5;
    border-radius: 0.025rem;
    padding: 0.1rem 0.2rem;
    .point{
      display: block;
      width: .2rem;
      height: .2rem;
      border-radius: 50%;
      background-color: #dc3545;
      margin-right: .1rem;
    }
  }
  .web3modal-modal-lightbox {
    transition: opacity 0.1s ease-in-out 0s;
    text-align: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    margin-left: -50vw;
    top: 0px;
    left: 50%;
    z-index: 2;
    will-change: opacity;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    * {
      box-sizing: border-box !important;
    }
    .web3modal-modal-container {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 15px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      .web3modal-modal-hitbox {
        position: absolute;
        inset: 0px;
      }
      .web3modal-modal-card {
        position: relative;
        width: 100%;
        background-color: rgb(255, 255, 255);
        border-radius: 12px;
        margin: 10px;
        padding: 0px;
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
        max-width: 800px;
        min-width: fit-content;
        max-height: 100%;
        overflow: auto;
        .web3modal-provider-{
          &wrapper {
            width: 100%;
            padding: 8px;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            flex-direction: column;
            cursor: pointer;
            border-radius: 0px;
            border: 1px solid rgba(195, 195, 195, 0.14);
            &:hover{
              .web3modal-provider-container{
                background-color: rgba(195, 195, 195, 0.14);
              }
            }
          }
          &container{
            transition: background-color 0.2s ease-in-out 0s;
            width: 100%;
            display: flex;
            flex-direction: column;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            background-color: rgb(255, 255, 255);
            border-radius: 12px;
            padding: 24px 16px;
          }
          &icon {
            width: 45px;
            height: 45px;
            display: flex;
            border-radius: 50%;
            overflow: visible;
            box-shadow: none;
            -webkit-box-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            align-items: center;
            img {
              width: 100%;
              height: 100%;
            }
          }
          &name {
            width: 100%;
            font-size: 24px;
            font-weight: 700;
            margin-top: 0.5em;
            color: rgb(12, 12, 13);
          }
          &description {
            width: 100%;
            font-size: 18px;
            margin: 0.333em 0px;
            color: rgb(169, 169, 188);
          }
        }
        
      }
    }
  }  
  .bag{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .logoutBox{
      .dropdown{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: .3rem;
        .img{
          margin-right: .2rem;
        }
        .address{
          display: flex;
          align-items: flex-end;
          flex-direction: column;
          .layOut{
            font-size: .12rem;
            cursor: pointer;
          }
        }
        
      }
    }
    .buy{
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      max-width: 6rem;
      width: 100%;
      align-items: center;
      border: 1px solid #dee2e6;
      color: #fff;
      background-color: #6c757d;
      font-size: .26rem;
      line-height: 1.5;
      border-radius: 0.025rem;
      padding: 0.1rem 0.2rem;
    }
  }
}
@media screen and (max-width:500px) {
  .WalletCon .web3modal-modal-lightbox .web3modal-modal-container  .web3modal-modal-card {
    max-width: 800px;
    grid-template-columns: 1fr;
  }
  .WalletCon .web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-container{
      padding: 1vw;
  }
  .WalletCon .web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-icon {
      width: 8.5vw;
      height: 8.5vw;
  }
  .WalletCon .web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-name{
      font-size: 5vw;
  }
  .WalletCon .web3modal-modal-lightbox .web3modal-modal-container .web3modal-modal-card .web3modal-provider-description {
      font-size: 4vw;
  }
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
  margin-right: .2rem;
}

.spinner-border {
  display: inline-block;
  width: .3rem;
  height: .3rem;
  border: 0.28em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
  animation: .75s linear infinite spinner-border;
}

@keyframes spinner-border {
  100% {
      transform: rotate(360deg);
  }
}

